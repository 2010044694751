<template>
  <b-row v-if="!applyOverlay">
    <!-- Marca -->
    <b-col md="12">
      <b-row>
        <b-col md="6">
          <h5 class="mt-1">
            <strong>{{ $t('clubs.intellectualProperty.formTrademarks.labels.trademarkData') }}</strong>
          </h5>
        </b-col>
        <b-col md="6">
          <span
            v-if="nTrademarks > 1 && checkPermission(['DELETE_TRADEMARK_REGISTRATION', 'ALLOW_ALL'])"
            class="float-right mt-1 ml-1"
          >
            {{ $t( 'buttons.delete') }}
            <b-button
              class="btn-icon rounded-circle"
              variant="danger"
              @click="$emit('delete-trademark', actions.trademarkId)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </span>
          <span
            v-if="checkPermission(['EDIT_TRADEMARK_REGISTRATION', 'ALLOW_ALL'])"
            class="float-right mt-1"
          >
            {{ $t( 'buttons.edit') }}
            <b-button
              class="btn-icon rounded-circle"
              variant="secondary"
              @click="$emit('edit-trademark', actions.trademarkId)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
          </span>
        </b-col>
      </b-row>
      <hr style="margin-top: 8px;">
    </b-col>
    <b-col
      v-if="trademarkInfo"
      md="12"
    >
      <b-list-group>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.intellectualProperty.formTrademarks.labels.requestType') }}</strong>
            </b-col>
            <b-col md="6">
              <span class="float-left">{{ trademarkInfo.request_type.labelables[0].label }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.intellectualProperty.formTrademarks.labels.trademarkType') }}</strong>
            </b-col>
            <b-col md="6">
              <span class="float-left">{{ trademarkInfo.trademark_type.labelables[0].label }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.intellectualProperty.formTrademarks.labels.classNumber') }}</strong>
            </b-col>
            <b-col md="6">
              <span class="float-left">{{ trademarkInfo.class_number }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.intellectualProperty.formTrademarks.labels.classDescription') }}</strong>
            </b-col>
            <b-col md="6">
              <span class="float-left">{{ trademarkInfo.class_description }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.intellectualProperty.formTrademarks.labels.denomination') }}</strong>
            </b-col>
            <b-col md="6">
              <span class="float-left">{{ trademarkInfo.denomination }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.intellectualProperty.formTrademarks.labels.registerNumber') }}</strong>
            </b-col>
            <b-col md="6">
              <span class="float-left">{{ trademarkInfo.register_number }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.intellectualProperty.formTrademarks.labels.dateOfConcession') }}</strong>
            </b-col>
            <b-col md="6">
              <span class="float-left">{{ trademarkInfo.date_of_concession == null ? $t('generic.undefinedDate') : trademarkInfo.date_of_concession | formatDate() }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.intellectualProperty.formTrademarks.labels.effectiveDate') }}</strong>
            </b-col>
            <b-col md="6">
              <span class="float-left">{{ trademarkInfo.effective_date == null ? $t('generic.undefinedDate') : trademarkInfo.effective_date | formatDate() }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.intellectualProperty.formTrademarks.labels.applicationForTheRegistration') }}</strong>
            </b-col>
            <b-col md="6">
              <span>. . .</span>
              <b-media
                class="float-right"
                no-body
              >
                <span
                  class="i-c-pointer cursor-pointer float-right"
                  @click="showFile = !showFile"
                >
                  {{ $t('generic.see') }}
                  <feather-icon
                    v-if="!showFile"
                    icon="FileIcon"
                    size="17"
                    style="padding-bottom: 2px"
                  />
                </span>
                <div
                  v-if="showFile == true"
                  class="ml-2"
                  style="display: flex;"
                >
                  <div
                    v-if="checkPermission(['VIEW_CONTRACT_FILE_MEMBERS', 'ALLOW_ALL']) && showFile == true"
                    class="float-right"
                    style="display: flex;"
                  >
                    <div v-if="isObject(trademarkInfo.application_media_file) && trademarkInfo.application_media_file.mime_type != 'application/pdf'">
                      <image-viewer
                        class="cursor-pointer mr-1"
                        :document-id="trademarkInfo.application_file_hash"
                        :url-thumb="trademarkInfo.application_media_file.url_thumb"
                        :height="'30'"
                        :width="'30'"
                        :permission-show="'VIEW_CONTRACT_FILE_MEMBERS'"
                        :permission-download="'DOWNLOAD_CONTRACT_FILE_MEMBERS'"
                      />
                    </div>
                    <div v-else>
                      <ViewerDocumentModal
                        class="cursor-pointer mr-1"
                        :title="trademarkInfo.application_media_file ? trademarkInfo.application_media_file.title : ''"
                        :document-id="trademarkInfo.application_file_hash"
                        :height="'30'"
                        :width="'30'"
                        :permission-show="'VIEW_CONTRACT_FILE_MEMBERS'"
                        :permission-download="'DOWNLOAD_CONTRACT_FILE_MEMBERS'"
                      />
                    </div>
                    <div v-if="trademarkInfo.application_file_hash != null && checkPermission(['DOWNLOAD_CONTRACT_FILE_MEMBERS', 'ALLOW_ALL'])">
                      <span
                        class="float-right cursor-pointer"
                        @click="handleDownloadFile(trademarkInfo.application_file_hash, trademarkInfo.application_media_file.title)"
                      >
                        <i
                          class="icon-0-icons-dark-download btn-cursor"
                          style="font-size: 20px;"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </b-media>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.intellectualProperty.formTrademarks.labels.trademarkImage') }}</strong>
            </b-col>
            <b-col md="6">
              <span>. . .</span>
              <b-media
                class="float-right"
                no-body
              >
                <span
                  class="i-c-pointer cursor-pointer float-right"
                  @click="showFileTwo = !showFileTwo"
                >
                  {{ $t('generic.see') }}
                  <feather-icon
                    v-if="!showFileTwo"
                    icon="FileIcon"
                    size="17"
                    style="padding-bottom: 2px"
                  />
                </span>
                <div
                  v-if="showFileTwo == true"
                  class="ml-2"
                  style="display: flex;"
                >
                  <div
                    v-if="checkPermission(['VIEW_CONTRACT_FILE_MEMBERS', 'ALLOW_ALL']) && showFileTwo == true"
                    class="float-right"
                    style="display: flex;"
                  >
                    <div v-if="isObject(trademarkInfo.image_media_file) && trademarkInfo.image_media_file.mime_type != 'application/pdf'">
                      <image-viewer
                        class="cursor-pointer mr-1"
                        :document-id="trademarkInfo.image_file_hash"
                        :url-thumb="trademarkInfo.image_media_file.url_thumb"
                        :height="'30'"
                        :width="'30'"
                        :permission-show="'VIEW_CONTRACT_FILE_MEMBERS'"
                        :permission-download="'DOWNLOAD_CONTRACT_FILE_MEMBERS'"
                      />
                    </div>
                    <div v-else>
                      <ViewerDocumentModal
                        class="cursor-pointer mr-1"
                        :title="trademarkInfo.image_media_file ? trademarkInfo.image_media_file.title : ''"
                        :document-id="trademarkInfo.image_file_hash"
                        :height="'30'"
                        :width="'30'"
                        :permission-show="'VIEW_CONTRACT_FILE_MEMBERS'"
                        :permission-download="'DOWNLOAD_CONTRACT_FILE_MEMBERS'"
                      />
                    </div>
                    <div v-if="trademarkInfo.image_file_hash != null && checkPermission(['DOWNLOAD_CONTRACT_FILE_MEMBERS', 'ALLOW_ALL'])">
                      <span
                        class="float-right cursor-pointer"
                        @click="handleDownloadFile(trademarkInfo.image_file_hash, trademarkInfo.image_media_file.title)"
                      >
                        <i
                          class="icon-0-icons-dark-download btn-cursor"
                          style="font-size: 20px;"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </b-media>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-col>
    <!--/ Marca -->
    <login-modal
      :permission="validPermission"
      @change="toAccess"
    />
  </b-row>
  <b-row v-else>
    <b-col
      md="12"
      class="i-height-div"
    />
  </b-row>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mapActions } from 'vuex'
import { isObject, downloadFile } from '@core/utils/utils'
import { BListGroup, BListGroupItem, BButton } from 'bootstrap-vue'
import trademarkService from '@/services/trademarkService'
import ImageViewer from '@/views/member/ImageViewer.vue'
import ViewerDocumentModal from '@/views/member/ViewerDocumentModal.vue'
import LoginModal from '@/views/member/LoginModal.vue'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BButton,
    ImageViewer,
    ViewerDocumentModal,
    LoginModal,
  },

  props: {
    applyOverlay: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Object,
      default: () => {},
    },
    nTrademarks: {
      type: Number,
      default: null,
    },
    checkPermission: {
      type: Function,
      default: null,
    },
  },

  data() {
    return {
      validPermission: null,
      showFile: false,
      showFileTwo: false,
      trademarkInfo: {},
    }
  },

  watch: {
    'actions.trademarkId': function () {
      this.trademarkInfo = {}
      this.fetchTrademarkInfo()
    },
  },

  methods: {
    downloadFile,
    isObject,
    ...mapActions({ mediaFileShow: 'mediaFile/show' }),

    fetchTrademarkInfo() {
      trademarkService.getTrademarkInfo(this.actions.trademarkId).then(({ data }) => {
        this.trademarkInfo = data.data
        this.$emit('stop-overlay')
      }).catch(error => {
        this.$emit('stop-overlay')
        this.responseCatch(error)
      })
    },

    async toAccess(permissionData) {

    },

    async handleDownloadFile(fileHash, title) {
      this.isLoading = true
      await this.mediaFileShow(fileHash).then(response => {
        const { data: dataDocument } = response
        this.downloadFile(dataDocument, title)
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false
        this.responseCatch(error)
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>

<style scoped>
.i-c-pointer:hover {
  color: #6abeff;
}
</style>
